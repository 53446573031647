import React from 'react';
import MainTemplate from 'templates/Main/MainTemplate';

const NotFoundPage = data => (
    <MainTemplate>

    </MainTemplate>
);

export default NotFoundPage;

